import type { RumInitConfiguration } from '@datadog/browser-rum';
import type { LogsInitConfiguration } from '@datadog/browser-logs';

type SharedDatadogOptions =
  | 'clientToken'
  | 'env'
  | 'sessionSampleRate'
  | 'service'
  | 'site'
  | 'version'
  | 'beforeSend'
  | 'telemetrySampleRate';

/**
 * Whether or not application is running in local dev
 */
export const isLocal =
  typeof window !== 'undefined' && window.location.hostname === 'localhost';

/**
 * Configuration shared between Datadog RUM and Logger libs
 */
export const sharedInitOptions: Pick<
  RumInitConfiguration,
  SharedDatadogOptions
> &
  Pick<LogsInitConfiguration, SharedDatadogOptions> = {
  clientToken: 'pubcf322a836f497faaf55ef76616d06b5a',
  site: 'datadoghq.com', // cspell:disable-line
  env: process.env.NEXT_PUBLIC_APP_ENV,
  version: process.env.NEXT_PUBLIC_APP_VERSION,
  service: 'identity-ui',
  sessionSampleRate: isLocal ? 0 : 100,
  telemetrySampleRate: isLocal ? 0 : 100,
};
